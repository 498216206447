

@media (prefers-reduced-motion: no-preference) {
  
}

body{
  margin: 0px
}

.App{
  
}
textarea {
  width: 100%;
  height: 40vh;
  
  
  box-sizing: border-box;
  border: 2px solid #ccf;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none
}

.Result {
  height: 50vh;
}

.button {
  background-color:lightgreen;
  border-radius: 4px;
  padding: 2px;
  border-color:black;
  margin: 2px;
  cursor: pointer;
}

.Footer {
  background-color: lightgray;
  text-align: center;
  
}